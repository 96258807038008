import React, { useState } from "react"
import { Button } from "reactstrap"
import { toast } from "react-toastify"

const ConfirmModal = props => {
  const [modalLoading, setModalLoading] = useState(false)
  const { onConfirm, afterConfirm, onClose, message, isNoToast, showLoadingIndicator } = props
  const handleConfirm = () => {
    setModalLoading(true)
    if (isNoToast) {
      onConfirm()
      onClose()
      return
    }
    onConfirm()
      .then(response => {
        if (afterConfirm) {
          const {
            status: { description },
          } = response
          afterConfirm()
          setModalLoading(false)
          onClose()
          toast(`${description}`, {
            type: "success",
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        } else {
          // for support page
          const {
            data: {
              _links: { web },
            },
          } = response
          props.handleTicketLink(web)
          setModalLoading(false)
          onClose()
        }
      })
      .catch(error => {
        setModalLoading(false)
        toast(`${error}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }
  return (
    <>
      <div
        style={{ margin: "0 3rem 1rem 3rem" }}
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
      {onConfirm ? (
        <div class="modal-footer" style={{ justifyContent: "space-between" }}>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          {showLoadingIndicator === true && modalLoading && (
            <>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                <span className="sr-only">Loading...</span>
              </span>
            </>
          )}
          <Button color="primary" disabled={modalLoading} onClick={() => handleConfirm()}>
            OK
          </Button>
        </div>
      ) : (
        <div class="modal-footer">
          <Button color="secondary" onClick={onClose}>
            OK
          </Button>
        </div>
      )}
    </>
  )
}

export default ConfirmModal
